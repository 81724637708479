/*
| Developed by Dirupt
| Filename : index.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Header from '../common/HeaderSimple'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	children: React.ReactNode
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function CompactLayout({ children, maxWidth = 'sm' }: Props) {
	return (
		<>
			<Header />

			<Container component="main">
				<Stack
					sx={{
						py: 12,
						m: 'auto',
						minHeight: '100vh',
						textAlign: 'center',
						justifyContent: 'center',
						maxWidth,
					}}
				>
					{children}
				</Stack>
			</Container>
		</>
	)
}
