/*
| Developed by Dirupt
| Filename : 403-view.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { ForbiddenIllustration } from '@/assets/illustrations'
import ErrorLayout from '@/components/modules/error/ErrorLayout'

/*
|--------------------------------------------------------------------------
| Page component
|--------------------------------------------------------------------------
*/
export default function ErrorPage403() {
	return (
		<ErrorLayout
			title="Accès interdit"
			description="La page à laquelle vous essayez d'accéder a un accès restreint. Veuillez vous adresser à l'administrateur système."
			illustration={<ForbiddenIllustration sx={{ height: 360, my: { xs: 5, sm: 10 } }} />}
		/>
	)
}
