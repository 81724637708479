/*
| Developed by Dirupt
| Filename : layout.config.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
export const HEADER = {
	H_MOBILE: 64,
	H_DESKTOP: 80,
	H_DESKTOP_OFFSET: 80 - 16,
}

/*
|--------------------------------------------------------------------------
| Nav
|--------------------------------------------------------------------------
*/
export const NAV = {
	W_VERTICAL: 280,
	W_MINI: 88,
}
