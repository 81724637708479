/*
| Developed by Dirupt
| Filename : HeaderSimple.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import HeaderShadow from './HeaderShadow'
import { HEADER } from '@/config/layout.config'
import { useOffSetTop } from '@/hooks/useOffsetTop'
import { RouterLink } from '@/routes/components'
import { urlsConfig } from '@/config/urls.config'
import Logo from '@/components/logo'
import { bgBlur } from '@/theme/css'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function HeaderSimple() {
	const theme = useTheme()
	const offsetTop = useOffSetTop(HEADER.H_DESKTOP)

	return (
		<AppBar>
			<Toolbar
				sx={{
					justifyContent: 'space-between',
					height: {
						xs: HEADER.H_MOBILE,
						md: HEADER.H_DESKTOP,
					},
					transition: theme.transitions.create(['height'], {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.shorter,
					}),
					...(offsetTop && {
						...bgBlur({
							color: theme.palette.background.default,
						}),
						height: {
							md: HEADER.H_DESKTOP_OFFSET,
						},
					}),
				}}
			>
				<Logo />

				<Stack direction="row" alignItems="center" spacing={1}>
					<Link
						href={urlsConfig.contactUs}
						component={RouterLink}
						color="inherit"
						sx={{ typography: 'subtitle2' }}
						aria-label={"Besoin d'aide ?"}
					>
						Besoin d'aide ?
					</Link>
				</Stack>
			</Toolbar>

			{offsetTop ? <HeaderShadow /> : null}
		</AppBar>
	)
}
