/*
| Developed by Dirupt
| Filename : pathsConfig.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

export const urlsConfig = {
	customerPortal: 'https://clients.shpv.fr/',
	home: '/',
	contactUs: '/nous-contacter',
	askQuote: '/demander-un-devis',
	legalNotices: '/mentions-legales',
	privacyPolicy: '/politiques-de-confidentialite',
	services: {
		index: '/services',
		infrastructure: '/services/infrastructure',
		infogerance: '/services/infogerance',
		datacenter: '/services/datacenter',
	},
	legals: {
		cgHosting: '/legals/SHPV-CGV-Hebergement.pdf',
		cgvCloud: '/legals/SHPV-CGV-Cloud.pdf',
		cgvOnPremise: '/legals/SHPV-CGV-OnPremise.pdf',
		additionalServices: '/legals/SHPV-Services-Annexes.pdf',
	},
}
