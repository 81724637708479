/*
| Developed by Dirupt
| Filename : not-found-view.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { PageNotFoundIllustration } from '@/assets/illustrations'
import ErrorLayout from '@/components/modules/error/ErrorLayout'

/*
|--------------------------------------------------------------------------
| Page component
|--------------------------------------------------------------------------
*/
export default function ErrorPage404() {
	return (
		<ErrorLayout
			title="Désolé, la page est introuvable !"
			description="Désolé, nous n'avons pas trouvé la page que vous recherchez. Peut-être avez-vous mal saisi l'URL ? Vérifiez votre orthographe."
			illustration={
				<PageNotFoundIllustration
					sx={{
						height: 360,
						my: { xs: 5, sm: 10 },
					}}
				/>
			}
		/>
	)
}
