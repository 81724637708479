/*
| Developed by Dirupt
| Filename : 500-view.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { SeverErrorIllustration } from '@/assets/illustrations'
import ErrorLayout from '@/components/modules/error/ErrorLayout'

/*
|--------------------------------------------------------------------------
| Page component
|--------------------------------------------------------------------------
*/
export default function ErrorPage500() {
	return (
		<ErrorLayout
			title="500 - Erreur serveur"
			description="Il y a eu une erreur, veuillez réessayer plus tard."
			illustration={<SeverErrorIllustration sx={{ height: 360, my: { xs: 5, sm: 10 } }} />}
		/>
	)
}
