/*
| Developed by Dirupt
| Filename : ErrorLayout.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import React from 'react'
import { m } from 'framer-motion'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Button, { ButtonProps } from '@mui/material/Button'
import CompactLayout from '@/layouts/compact'
import { MotionContainer, varBounce } from '@/components/animate'
import { RouterLink } from '@/routes/components'
import { urlsConfig } from '@/config/urls.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	title: string
	titleProps?: TypographyProps
	description: string
	descriptionProps?: TypographyProps
	illustration?: React.ReactNode
	renderButton?: React.ReactNode
	buttonProps?: ButtonProps
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function ErrorLayout({
	title,
	titleProps,
	description,
	descriptionProps,
	illustration,
	buttonProps,
	renderButton,
	maxWidth,
}: Props) {
	return (
		<CompactLayout maxWidth={maxWidth}>
			<MotionContainer>
				<m.div variants={varBounce().in}>
					<Typography variant="h3" {...titleProps} sx={{ mb: 2, ...titleProps?.sx }}>
						{title}
					</Typography>
				</m.div>

				<m.div variants={varBounce().in}>
					<Typography {...descriptionProps} sx={{ color: 'text.secondary', ...descriptionProps?.sx }}>
						{description}
					</Typography>
				</m.div>

				{illustration ? <m.div variants={varBounce().in}>{illustration}</m.div> : null}

				<m.div variants={varBounce().in}>
					{renderButton ? (
						renderButton
					) : (
						<Button
							aria-label={'Retour à l’accueil'}
							component={RouterLink}
							href={urlsConfig.home}
							size={'large'}
							variant={'contained'}
							{...buttonProps}
						>
							{buttonProps?.children || 'Retour à l’accueil'}
						</Button>
					)}
				</m.div>
			</MotionContainer>
		</CompactLayout>
	)
}
